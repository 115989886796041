export const environment = {
  production: false,
  staging: true,
  meteorServer: 'https://staging-api.adtonos.com/',
  streamerUrl: 'https://staging-play.adtonos.com/',
  trackingServer: 'https://staging-play.adtonos.com/',
  vastUrl: 'https://staging-play.adtonos.com/',
  openrtbUrl: 'https://staging-openrtb.adtonos.com/',
  filesUrl: 'https://staging-files.adtonos.com/',
  demoPlayer: 'https://staging-play.adtonos.com/demo-player/',
  sentryDsn: 'https://f85c03bd770a4924a24efe8226714e80@staging-sentry.adtonos.com/8',
  exchangeUrl: 'https://staging-exchange.adtonos.com/',
  exchangeVastAdapterUrl: 'https://vast-adapter.staging.adtonos.com/',
};
